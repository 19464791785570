<script setup>
import { mapErrors } from '@/utilities';
import { useForm, usePage, router } from '@inertiajs/vue3';

import { computed, inject, ref } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import Icon from '@/Components/Icon.vue';

// Icons
import deleteIcon from '@i/delete-icon-blue.svg';
import saveIcon from '@i/save-white.svg';

const route = inject('route');
const props = defineProps({
    teamable: Object,
    team: Object,
});

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
});

const teamOwner = computed(() => {
    if (props.teamable.team.user_id == usePage().props.user.id || usePage().props.user.type === 'staff') {
        return props.teamable.team.user_id;
    }

    return null;
});

const sendForm = () => {
    form.post(route('location.locations.team-members.store', { id: props.teamable.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => form.reset(),
    });
};
const deleteMember = (member) => {
    router.delete(
        route('location.locations.team-members.destroy', { location: props.teamable.id, team_member: member.id }),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
        }
    );
};

let resendButtonLoading = ref(false);
const resendAccountCreatedNotification = (member) => {
    resendButtonLoading.value = true;

    router.put(
        route('location.locations.team-members.update', { location: props.teamable.id, team_member: member.id }),
        {},
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onFinish: () => (resendButtonLoading.value = false),
        }
    );
};
</script>

<template>
    <FormSingleSettingBar :title="$t('Team')" removeInnerPadding>
        <div
            class="grid grid-cols-3 border-b text-blue border-blue/30 min-h-13"
            v-for="member in team.users"
            :key="member.id"
        >
            <div class="self-center font-bold">
                {{ member.first_name ? member.first_name + ' ' + member.last_name : $t(`We haven't met yet`) }}
            </div>
            <div class="self-center">{{ member.email }}</div>

            <div class="flex justify-end">
                <button
                    :disabled="resendButtonLoading"
                    type="button"
                    class="self-center mr-5 justify-self-end"
                    v-if="teamOwner && teamOwner != member.id"
                    @click="resendAccountCreatedNotification(member)"
                >
                    {{ $t(`Resend`) }}
                </button>
                <button
                    type="button"
                    class="self-center justify-self-end"
                    v-if="teamOwner && teamOwner != member.id"
                    @click="deleteMember(member)"
                >
                    <Icon :icon="deleteIcon" />
                </button>
            </div>
        </div>
        <div class="mt-8">
            <div class="flex items-stretch justify-end w-full gap-4">
                <div class="grid flex-1 grid-cols-5 gap-6">
                    <FormKit
                        type="text"
                        name="first_name"
                        :label="$t('First name')"
                        v-model="form.first_name"
                        :errors="form.errors.first_name"
                        :value="form.first_name"
                    />
                    <FormKit
                        type="text"
                        name="last_name"
                        :label="$t('Last name')"
                        v-model="form.last_name"
                        :errors="form.errors.last_name"
                        :value="form.last_name"
                    />
                    <FormKit
                        type="text"
                        name="email"
                        :label="$t('Email')"
                        v-model="form.email"
                        :errors="form.errors.email"
                        :value="form.email"
                        :classes="{
                            outer: 'col-span-2',
                        }"
                    />
                    <FormKit
                        type="text"
                        name="phone_number"
                        :label="$t('Mobile number')"
                        v-model="form.phone_number"
                        :errors="form.errors.phone_number"
                        :value="form.phone_number"
                        :help="$t('Using a non-Dutch number? Please also add the country code. (example +3234567890)')"
                    />
                </div>
                <div class="flex flex-col justify-start mt-7">
                    <Button
                        type="button"
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                        :text="$t('Add')"
                        :icon="saveIcon"
                        @click="sendForm"
                    />
                </div>
            </div>
        </div>
    </FormSingleSettingBar>
</template>
